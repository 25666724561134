import { FC } from 'react';
import RelatedGrid from '@components/relatedGrid/RelatedGrid';
import { SeeAll } from '@components/seeAll';
import { Title } from '@components/title';
import { ITipCard } from '@shared/models/tipsAndTricks.model';
import { ModuleType } from '@shared/types/module.type';
import { Container } from './TipsAndTricks.style';
interface Props {
  data: ITipCard[];
}
const TipsAndTricks: FC<Props> = ({
  data
}: Props) => {
  return <Container>
            <RelatedGrid cardType={ModuleType.TipsTricks} title={<Title text="เคล็ดลับ ฉบับซีพี" extra={<SeeAll url="/tips-tricks" pathForTracking="tips_tricks" />} />} dataList={data} desktopColumn={3} mobileColumn={1} eventSection="tips_tricks" />
        </Container>;
};
export default TipsAndTricks;