import { FC } from 'react';
import Image from 'next/image';
import { IRegisterBanner } from '@modules/registerBanner/domain/models/registerBanner.model';
import { Content } from '@components/layouts';
import { useWindowSize } from '@shared/utils/hooks';
import { useUtag } from '@shared/utils/hooks/useUtag.utils';
import breakpoint from '../../../shared/assets/breakpoint.json';
import { BannerRegister, ImgBanner, ReflexShadow } from './RegisterBanner.style';
interface Props {
  banner: IRegisterBanner;
}
const RegisterBanner: FC<Props> = ({
  banner
}: Props) => {
  const {
    width
  } = useWindowSize();
  const utag = useUtag();
  const handleOnClickTracker = (title: string) => {
    utag.link({
      tealium_event: 'banner_register_click',
      banner_name: title
    });
  };
  return <Content>
            <BannerRegister onClick={() => {
      handleOnClickTracker('banner_register');
    }}>
                <a href={banner.linkOutUrl} target="_blank" aria-label="register banner">
                    <ImgBanner src={width > breakpoint.md - 1 ? banner.bannerDesktop : banner.bannerMobile} width={1140} height={440} alt={banner.title} />
                    <ReflexShadow>
                        <Image src={width > breakpoint.md - 1 ? banner.bannerDesktop : banner.bannerMobile} width={1140} height={440} alt={banner.title} />
                    </ReflexShadow>
                </a>
            </BannerRegister>
        </Content>;
};
export default RegisterBanner;