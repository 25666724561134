import { TipsTricksWithCategoryResponse } from '@modules/libs/graphql/generated/graphql';
import { ITipCard } from '@shared/models/tipsAndTricks.model';
import { convertDate } from '@shared/utils/convertDate.utils';
export class RecommendTipsTricksEntityMapper {
  public static toDomain(tipResponse: TipsTricksWithCategoryResponse): ITipCard {
    const {
      id,
      title,
      description,
      thumbUrl,
      publishedAt,
      slugUrl,
      category
    } = tipResponse;
    return {
      id,
      title,
      description,
      imageUrl: thumbUrl,
      publishDate: convertDate(publishedAt, 'th', 'DD MMM BBBB'),
      categories: category.map(({
        title
      }) => title),
      viewer: 24000,
      slugUrl
    };
  }
}