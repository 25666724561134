import { FC } from 'react';
import { motion } from 'framer-motion';
import { useWindowSize } from '@shared/utils/hooks';
import breakpoint from '../../../../../shared/assets/breakpoint.json';
import { ButtonContainer } from './SideButton.style';
interface Props {
  title: string;
  href: string;
  src: string;
  onClick: () => void;
}
const SideButton: FC<Props> = ({
  title,
  href,
  src,
  onClick
}: Props) => {
  const {
    width
  } = useWindowSize();
  const isMobile = width < breakpoint.md;
  const imageAnimate = {
    animate: {
      transform: 'rotate(5deg) scale(1.2)',
      transition: {
        duration: 1.3,
        type: 'spring',
        bounce: 0.7
      }
    }
  };
  const textAnimate = {
    animate: {
      color: "#EE843C",
      transform: 'scale(1.2)',
      transition: {
        duration: 1.2,
        type: 'spring',
        bounce: 0.6
      }
    }
  };
  return <ButtonContainer whileHover={isMobile ? 'none' : 'animate'} onClick={onClick}>
            <a href={href} target="_blank" aria-label="cp brand site">
                <motion.img variants={imageAnimate} key={src} src={src} alt="cp brand site" initial={{
        transform: 'rotate(0deg) scale(1)'
      }} width={88} height={88} />
                <motion.span variants={textAnimate} initial={{
        transform: 'scale(1)'
      }}>
                    {title}
                </motion.span>
            </a>
        </ButtonContainer>;
};
export default SideButton;