import { FC } from 'react';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { isEmpty } from 'radash';
import { Container, ImgProduct, ListTag, ProductCardAction, ProductTitle, ProductWeight } from './ProductCard.style';
const Tag = dynamic(() => import('@components/tag/Tag'));
interface Props {
  imageUrl: string;
  title?: string;
  weight?: string;
  tag?: string[];
  maxLineName?: number;
  slug: string;
}
const ProductCard: FC<Props> = ({
  imageUrl,
  title,
  weight,
  tag,
  maxLineName,
  slug
}: Props) => {
  return <Link href={`/product/${slug}`}>
            <Container>
                <ImgProduct src={imageUrl || '/images/default/product.jpg'} alt={title} onError={({
        currentTarget
      }) => {
        currentTarget.onerror = null; // prevents looping
        currentTarget.src = '/images/default/product.jpg';
      }} width={227} height={227} />
                <div>
                    <ProductTitle $maxLineName={maxLineName}>
                        {title}
                    </ProductTitle>
                    <ProductWeight>{weight}</ProductWeight>
                    <ProductCardAction>
                        <ListTag>
                            {!isEmpty(tag) && tag.map((text, index) => <Tag key={index} text={text} />)}
                        </ListTag>
                    </ProductCardAction>
                </div>
            </Container>
        </Link>;
};
export default ProductCard;