import { FC } from 'react';
import { ProductCard } from '@components/product/card';
import { SeeAll } from '@components/seeAll';
import { Title } from '@components/title';
import { IProduct } from '@shared/models/product.model';
import { useUtag } from '@shared/utils/hooks/useUtag.utils';
import { Container, ProductGridWrapper } from './RecommendProduct.style';
interface Props {
  data: IProduct[];
}
const RecommendProduct: FC<Props> = ({
  data
}: Props) => {
  const utag = useUtag();
  return <Container>
            <Title text={'ผลิตภัณฑ์ซีพีแนะนำ'} extra={<SeeAll url="/product" pathForTracking="product" />} />
            <ProductGridWrapper>
                {data.map((product, index) => <div key={product.id} onClick={() => {
        utag.link({
          tealium_event: 'recommended_product_click',
          position: index + 1
        });
      }}>
                        <ProductCard imageUrl={product.imageUrl} title={product.name} weight={product.weight} tag={product.tag} slug={product.slugUrl} />
                    </div>)}
            </ProductGridWrapper>
        </Container>;
};
export default RecommendProduct;