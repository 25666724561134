import { styled } from 'styled-components';
import { match } from 'ts-pattern';
import { ButtonSizeType } from '@shared/types/buttonSize.type';
export const Container = styled.div.withConfig({
  displayName: "PointButtonstyle__Container",
  componentId: "sc-uk7du4-0"
})(["", " box-shadow:0px -3px 0px 0px #F8A323 inset,0px 1px 0px 0px #FFF8E4 inset;font-family:DB HeaventRounded;padding-top:", ";padding-bottom:", ";padding-left:", ";padding-right:", ";img{", " width:", ";height:", ";}span{", " font-size:", ";line-height:", ";}"], {
  "display": "flex",
  "width": "fit-content",
  "alignItems": "center",
  "justifyContent": "center",
  "gap": "0.25rem",
  "borderRadius": "100px",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(255 212 124 / var(--tw-bg-opacity))"
}, ({
  $size
}) => `${renderSize($size).paddingTop}px`, ({
  $size
}) => `${renderSize($size).paddingBottom}px`, ({
  $size
}) => `${renderSize($size).paddingLeft}px`, ({
  $size
}) => `${renderSize($size).paddingRight}px`, {
  "aspectRatio": "1 / 1"
}, ({
  $size
}) => `${renderSize($size).imageWidth}px`, ({
  $size
}) => `${renderSize($size).imageHeight}px`, {
  "fontSize": "32px",
  "fontWeight": "900",
  "--tw-text-opacity": "1",
  "color": "rgb(217 120 55 / var(--tw-text-opacity))"
}, ({
  $size
}) => `${renderSize($size).fontSize}px`, ({
  $size
}) => `${renderSize($size).lineHeight}px`);
const renderSize = (btnSize: ButtonSizeType) => {
  return match(btnSize).with(ButtonSizeType.Small, () => {
    return {
      imageWidth: '22',
      imageHeight: '22',
      fontSize: '21',
      lineHeight: '26',
      paddingTop: '1',
      paddingBottom: '1',
      paddingLeft: '6',
      paddingRight: '8'
    };
  }).with(ButtonSizeType.Large, () => {
    return {
      imageWidth: '32',
      imageHeight: '32',
      fontSize: '32',
      lineHeight: '30',
      paddingTop: '8',
      paddingBottom: '8',
      paddingLeft: '8',
      paddingRight: '12'
    };
  }).otherwise(() => {
    return {
      imageWidth: '32',
      imageHeight: '32',
      fontSize: '32',
      lineHeight: '26',
      paddingTop: '8',
      paddingBottom: '8',
      paddingLeft: '8',
      paddingRight: '12'
    };
  });
};