import { FC } from 'react';
import Image from 'next/image';
import numeral from 'numeral';
import { userStore } from '@modules/user/adapter/outbound/stores/user.store';
import { ButtonSizeType } from '@shared/types/buttonSize.type';
import { useUtag } from '@shared/utils/hooks/useUtag.utils';
import { Container } from './PointButton.style';
interface Props {
  size: ButtonSizeType;
}
const PointButtonLogic: FC<Props> = ({
  size
}: Props) => {
  const utag = useUtag();
  const {
    userDetail
  } = userStore(state => state);
  return <Container onClick={() => utag.link({
    tealium_event: 'cp_point_click'
  })} $size={size}>
            <Image src="/images/home/banner/cp-coin.png" alt="cp-coin" width={32} height={32} />
            <span>{numeral(userDetail?.point).format('0a').toUpperCase()}</span>
        </Container>;
};
export default PointButtonLogic;