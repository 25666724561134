import { motion } from 'framer-motion';
import styled from 'styled-components';
export const ButtonContainer = styled(motion.div).withConfig({
  displayName: "SideButtonstyle__ButtonContainer",
  componentId: "sc-azm7pk-0"
})(["", " background-color:#b6bdd8;transition:box-shadow 0.1s;&::before{content:' ';", " background-color:#dfe5f0;transition:box-shadow 0.1s;}&::after{content:' ';", " transition:box-shadow 0.1s;}&:hover{&::before{box-shadow:0 1px 0 0 #dfe5f0;transition:box-shadow 0.1s;}&::after{box-shadow:0 1px 0 0 white;transition:box-shadow 0.1s;}}a{", "}img{", "}span{", ";font-family:DB HeaventRounded;}@media (max-width:", "){a{", "}img{", "}span{", "}}"], {
  "position": "relative",
  "zIndex": "1",
  "display": "flex",
  "borderRadius": "1.5rem"
}, {
  "position": "absolute",
  "top": "-5px",
  "zIndex": "1",
  "height": "100%",
  "width": "100%",
  "borderRadius": "1.5rem"
}, {
  "position": "absolute",
  "top": "-10px",
  "zIndex": "1",
  "height": "100%",
  "width": "100%",
  "borderRadius": "1.5rem",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(255 255 255 / var(--tw-bg-opacity))"
}, {
  "marginTop": "-34px",
  "display": "flex",
  "flexDirection": "column",
  "alignItems": "center",
  "justifyContent": "center",
  "paddingLeft": "0.75rem",
  "paddingRight": "0.75rem",
  "paddingBottom": "1rem"
}, {
  "position": "relative",
  "zIndex": "2",
  "height": "auto",
  "width": "88px !important"
}, {
  "position": "relative",
  "zIndex": "2",
  "marginTop": "-2px",
  "display": "flex",
  "justifyContent": "center",
  "padding": "0px",
  "textAlign": "center",
  "fontSize": "1.125rem",
  "lineHeight": "1.75rem",
  "fontWeight": "900",
  "--tw-text-opacity": "1",
  "color": "rgb(88 88 88 / var(--tw-text-opacity))"
}, ({
  theme
}) => theme.breakpoint.lg, {
  "marginTop": "-2.5rem",
  "paddingLeft": "0.375rem",
  "paddingRight": "0.375rem",
  "paddingBottom": "0.75rem"
}, {
  "width": "80px !important"
}, {
  "fontSize": "1rem",
  "lineHeight": "1.5rem"
});